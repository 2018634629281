import React, { Fragment } from 'react';
import { FlexedDiv } from '../components';
import { LABEL, Fs12RegSecBlack } from '../constants';
import { IInvestment } from '../pages/OrderDetails/orderSummaryTypes';
import { investorAmountStyle } from '../styles';
interface ITotalInvestment {
    totalInvestment: Array<IInvestment>;
}
export const TotalInvestment: React.FC<ITotalInvestment> = ({ totalInvestment }: ITotalInvestment) => {
    return (
        <FlexedDiv direction="column" id="total-investment">
            <Fs12RegSecBlack>{LABEL.totalInvestment}</Fs12RegSecBlack>
            <FlexedDiv>
                {totalInvestment.map((item, index) => (
                    <Fragment key={index}>
                        <div style={{ ...investorAmountStyle, fontWeight: 500 }}>{item.fundCurrency}&nbsp;</div>
                        <div style={investorAmountStyle}>{item.investmentAmount}</div>
                        {index + 1 !== totalInvestment.length ? <div>&nbsp;{LABEL.plus}&nbsp;</div> : null}
                    </Fragment>
                ))}
            </FlexedDiv>
        </FlexedDiv>
    );
};
